<template>
  <div class="link-container">
    <div class="text-center">
      <div>
        <div class="text-center mb-2">
          <img src="../assets/images/icon.svg" alt="RegAlytics Logo" width="60px">
          <p class="mt-2 mb-0 text-muted">Powered by <br><span class="text-green fw-bold h4">RegAlytics</span></p>
        </div>
        <div class="w-25 mx-auto">
          <hr>
        </div>

        <div v-if="!error">
          <p class="small text-muted">Loading website...</p>
          <spinner class="text-muted"/>
        </div>
        <p v-else class="text-danger">
          There was an issue fetching this website! Please try again
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
import axios from "@/axios";

export default {
  name: "Link",
  components: {Spinner},

  data() {
    return {
      error: false
    }
  },

  methods: {

    getFullUrl() {
      this.error = false;
      const artical_id = this.$route.params.artical_id
      const user_id = this.$route.params.user_id
      const camp = this.$route.params.camp
      const shortUrl = `/telemetry/get-sort-url/${artical_id}/${user_id}/${camp}`

      axios.get(`/telemetry/get-sort-url/${artical_id}/${user_id}/${camp}`, { withCredentials: true })
      .then(response => {
        this.$tracking.trackEvent('loadedShortUrl', {
          articleId: response.data.article_id,
          campaignType: response.data.campaign_type,
          shortenedUrlId: response.data.shortened_url_id,
          shortenedUrl: shortUrl,
          originalUrl: response.data.original_url,
          createdByUserId: response.data.created_by_user_id
        });
        window.location = response.data.original_url;
      }).catch(() => {
        this.$store.dispatch('notifications/addNotification', {
          message: 'There was an issue fetching this website! Please try again',
          type: 'danger'
        });
        this.error = true;
      });

    }

  },

  created() {
    this.getFullUrl();
  }

}
</script>

<style scoped>
.link-container {
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
</style>
